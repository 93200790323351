<template>
    <LiefengContent>
        <template v-slot:title>内容模板</template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true">
                <FormItem>
                    <Input :maxlength="20" v-model.trim="templateName" placeholder="请输入模板名称" style="width: 150px"></Input>
                </FormItem>
                <Button type="primary" icon="ios-search-outline" @click="search" style="margin-right: 10px">查询</Button>
                <Button type="success" @click="reset" icon="ios-refresh">重置</Button>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :total="total"
                :curPage="currentPage"
                @hadlePageSize="pageChange"
                :loading="loading"
                :fixTable="true"
                :pagesizeOpts="[20, 30, 50, 100]"
                :pageSize="pageSize"
            ></LiefengTable>
            <LiefengModal title="推送给拟制人员" :value="addModel.status" @input="addGridStatusFn" :fullscreen="true">
                <template v-slot:contentarea></template>
            </LiefengModal>
            <Authorize ref="Authorize"></Authorize>
        </template>
    </LiefengContent>
</template>

<script>
//@route("/voterecord")
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import Authorize from '../childrens/Authorize'
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        Authorize
    },
    data() {
        return {
            loading: false,
            //选项列表
            //表头列表
            talbeColumns: [
                {
                    title: "栏目名称",
                    key: "columnName",
                    minWidth: 130,
                    align: "center",
                },
                {
                    title: "模板标题",
                    key: "templateName",
                    minWidth: 130,
                    align: "center",
                },
                {
                    title: "启用状态",
                    key: "enableStatus",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("i-switch", {
                            props: {
                                value: params.row.enableStatus === "1" ? true : false,
                            },
                            on: {
                                "on-change": res => {
                                    this.updateStatus(params.row,res)
                                },
                            },
                        })
                    },
                },
                {
                    title: "操作",
                    width: 280,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "default",
                                        size: "small",
                                    },
                                    style:{
                                        margin:'0 3px;',
                                        display : params.row.enableStatus == 1 ? '' : 'none'
                                    },
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.Authorize.modalStatus = true
                                        },
                                    },
                                },
                                "推送"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "default",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$Modal.confirm({
                                                title: "温馨提示",
                                                content: "<p>确认删除数据</p>",
                                                cancelText: "取消",
                                                okText: "确认",
                                                onOk: () => {
                                                    this.$post("/info/api/pc/information/template/deleteInformationTemplate", {
                                                        templateId: params.row.id
                                                    })
                                                    .then(res => {
                                                        if (res.code === "200") {
                                                            this.$Message.success({
                                                                content:'删除成功',
                                                                background:true
                                                            })
                                                            this.page = this.currentPage
                                                            this.getList();
                                                        }else{
                                                            this.$Message.error({content:"删除失败",background:true});
                                                        }
                                                    })
                                                    .catch(err => console.log(err));
                                                }
                                            });
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
                {
                    title: "创建时间",
                    key: "gmtCreate",
                    minWidth: 130,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
            ],
            tableData: [],
            total: 0,
            currentPage: 1,
            pageSize: 20,
            page: 1,

            // zhw添加
            addModel: {
                status: false,
                tip: false,
            },
            templateName: "",
            buttonRoot:''
        }
    },
    created() {
        let userButtonRoot = parent.vue.userButtonRoot;
        this.buttonRoot = userButtonRoot.includes("1001")
        ? "1001"
        : userButtonRoot.includes("1002")
        ? "1002"
        : userButtonRoot.includes("1003")
        ? "1003"
        : "";
        this.getList()
    },
    methods: {
        search() {
            this.page = 1
            this.getList()
        },
        reset() {
            this.templateName = ""
            this.page = 1
            this.getList()
        },
        // zhw添加的代码
        addGridStatusFn(status) {
            this.addModel.status = status
        },
        //修改res数据的时间格式
        getList() {
            this.loading = true
            this.$get("/info/api/pc/information/template/selectPage", {
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                staffId: parent.vue.loginInfo.userinfo.id,
                page: this.page,
                pageSize: this.pageSize,
                templateName: this.templateName,
                isManager: this.buttonRoot == '1003' ? "1" : '2',
                enableStatus:'',
            })
                .then(res => {
                    this.loading = false
                    if (res.code == 200) {
                        this.tableData = []
                        this.$nextTick(()=>{
                            this.tableData = res.dataList
                        })
                        this.currentPage = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async updateStatus(row,res){
            await this.$post('/info/api/pc/information/template/updateStatus',{
                templateId:row.id,
                status:!res ? '2' : '1'
            }).then(res=>{
                 if (res.code === "200") {
                    this.$Message.success({
                        content:'设置成功',
                        background:true
                    })
                    
                }else{
                    this.$Message.error({content:"设置失败",background:true});
                }
                this.page = this.currentPage
                this.getList();
            })
        }
    },
}
</script>
    
<style scoped lang='less'>
</style>